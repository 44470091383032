<template>
  <b-card no-body class="p-2">
    <b-form @submit.prevent>
      <b-row class="px-2 pb-1 mb-2 border-bottom">
        <b-col cols="7">
          <div class="d-flex align-items-end justify-content-end">
            <div class="w-100 d-inline-block mr-1">
              <label class="mb-0" for="search_text"><strong>카트 입력</strong></label>
              <b-form-input id="search_text" v-model="searchQuery" placeholder="카트를 스캔해주세요" autocomplete="off" />
            </div>
            <div class="d-flex justify-content-between" style="width: 330px">
              <b-button variant="primary" class="mr-1" @click="searchData">
                <div class="d-flex justify-content-center align-items-center">
                  <feather-icon :icon="'SearchIcon'" class="mr-1"></feather-icon>
                  확인
                </div>
              </b-button>
            </div>
            <div class="d-flex justify-content-between" style="width: 330px">
              <b-button variant="success" class="mr-1" @click="goPopstore">
                <div class="d-flex justify-content-center align-items-center">
                  <feather-icon :icon="'SearchIcon'" class="mr-1"></feather-icon>
                  주소 확인
                  <!-- <router-link to="/home" target="_blank">주소 확인</router-link> -->
                </div>
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="px-2 pb-1 mb-1 border-bottom">
        <b-col cols="12">
          <b-card
            header="등록 정보"
            bg-variant="transparent"
            border-variant="secondary"
            text-variant="primary"
            class="mb-1"
            >
            <b-card-text>
              <b-row>
                <b-col md="3" class="border-right">
                  <b-form-group
                    label="이름"
                    label-for="scanner_name"
                  >
                    <b-form-input
                      id="scanner_name"
                      placeholder="등록된 이름"
                      v-model="scannerData.name"
                      disabled
                    />
                  </b-form-group>
                  <b-form-group
                    label="카트 번호"
                    label-for="scanner_cart"
                  >
                    <b-form-input 
                    id="scanner_cart"
                    placeholder="등록된 카트번호"
                    v-model="scannerData.cart"
                    disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col md="5" class="border-right">
                  <b-form-group
                    label="휴대폰 번호"
                    label-for="scanner_phone"
                  >
                    <b-form-input 
                    id="scanner_phone"
                    placeholder="등록된 휴대폰 번호"
                    v-model="scannerData.phone"
                    disabled
                    />
                  </b-form-group>
                  <b-form-group
                    label="주소"
                    label-for="scanner_address"
                  >
                    <b-form-input 
                    id="scanner_address"
                    placeholder="주소"
                    v-model="scannerData.roadAddress"
                    disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="배송 날짜"
                    label-for="scanner_date"
                  >
                    <b-form-input
                    id="scanner_date"
                    placeholder="배송 날짜"
                    v-model="scannerData.delivaryDate"
                    disabled
                    />
                  </b-form-group>
                  <b-form-group
                    label="상세 주소"
                    label-for="scanner_address2"
                  >
                  <b-form-input 
                    id="scanner_address2"
                    placeholder="상세 주소"
                    v-model="scannerData.addAddress"
                    class="mt-25"
                    disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="px-2 mb-2">
        <b-col cols="7">
          <div class="d-flex align-items-end justify-content-end">
            <div class="w-100 d-inline-block mr-1">
              <label class="mb-0" for="search_text"><strong>상품 코드 입력</strong></label>
              <b-form-input id="search_text" v-model="searchQuery2" placeholder="상품 바코드를 스캔해주세요" autocomplete="off" />
            </div>
            <div class="d-flex justify-content-between" style="width: 330px">
              <b-button variant="primary" class="mr-1" @click="checkDatas">
                <div class="d-flex justify-content-center align-items-center">
                  <feather-icon :icon="'SearchIcon'" class="mr-1"></feather-icon>
                  확인
                </div>
              </b-button>
            </div>
          </div>
        </b-col>
        <b-col cols="5">
          <div class="d-flex align-items-end justify-content-end">
            <div class="w-100 d-inline-block mr-1">
              <label class="mb-0" for="search_text"><strong>상품 총 가격</strong></label>
              <b-form-input id="search_text" v-model="totalPrice" placeholder="상품 총 가격" autocomplete="off" />
            </div>
            <div class="d-flex justify-content-between" style="width: 180px;">
              <b-button variant="danger" class="mr-1" @click="resetPrice">
                <div class="d-flex justify-content-center align-items-center">
                  <feather-icon :icon="'RotateCwIcon'" class="mr-1"></feather-icon>
                  초기화
                </div>
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
      <div class="notice-container">
        <b-table
          ref="refProductsTable"
          class="position-relative"
          :items="testList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="해당 데이터가 없습니다"
          :sort-desc.sync="isSortDirDesc"
        >
        <template #cell(price)="data">
            <div class="d-flex justify-content-start align-items-center">
              <div class="text-nowrap">
                <b-form-input 
                  id="price"
                  v-model="data.item.price"
                  type="number"
                  />
              </div>
            </div>
          </template>
          <!-- Column: Actions -->
          <template #cell(action)="data">
            <div class="d-flex justify-content-start align-items-center">
              <div class="text-nowrap">
                <b-button
                  variant="flat-danger"
                  class="btn-icon"
                  @click="confirmDelete(data.item.id)"
                >
                  <feather-icon :id="`delete-row-${data.item.id}-read-icon`" icon="Trash2Icon" class="cursor-pointer color-info hover-color-success" size="21" />
                  <b-tooltip title="상품 삭제" class="cursor-pointer" placement="left" :target="`delete-row-${data.item.id}-read-icon`" />
                </b-button>
              </div>
            </div>
          </template>
        </b-table>
      </div>
    </b-form>
    <b-row>
      <b-col cols="12" class="d-flex justify-content-center align-items-center">
        <b-button variant="success" class="mr-1" @click="showModal">
          <div class="d-flex justify-content-center align-items-center">
            <feather-icon :icon="'CheckIcon'" class="mr-1"></feather-icon>
            확인
          </div>
        </b-button>
      </b-col>
    </b-row>
    <b-modal id="noticeShow" ref="refNoticeShowModal" size="lg" title="공지 내용" @shown="showModalShown()">
      <ShowModal ref="refShowNoticeComponent" />
      <template #modal-footer="{ ok }">
        <b-row class="w-100">
          <b-col cols="4" class="d-flex justify-content-start align-items-center">
            <span>총 가격 : </span>
            <span class="text-danger px-50">{{finishPrice}}</span>원
          </b-col>
          <b-col cols="8" class="d-flex justify-content-end align-items-center">
            <b-button variant="success" @click="payListSend()" class="mr-50">완료</b-button>
            <b-button variant="danger" @click="resetForm()">취소</b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BTable,
  BModal,
  BTooltip
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { onMounted, onUnmounted } from '@vue/composition-api'

import useIndex from './useIndex'
import scannerStoreModule from './scannerStoreModule'
import ShowModal from './ShowModal'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BTable,
    BModal,
    BTooltip,
    ShowModal,
  },
  directives: {
    Ripple,
  },
  setup() {
    const SCANNER_STORE_MODULE_NAME = 'scanner'

    // Register module
    if (!store.hasModule(SCANNER_STORE_MODULE_NAME)) store.registerModule(SCANNER_STORE_MODULE_NAME, scannerStoreModule)

    onMounted(() => {
      fetchStart();
    })
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SCANNER_STORE_MODULE_NAME)) store.unregisterModule(SCANNER_STORE_MODULE_NAME)
    })

    const {
      productList,
      fetchStart,
      scannerData,
      searchQuery,
      searchData,
      totalPrice,
      resetPrice,
      refProductsTable,
      tableColumns,
      fetchProducts,
      confirmDelete,
      refetchData,
      testList,
      refNoticeShowModal,
      refShowNoticeComponent,
      showModal,
      showModalShown,
      shownId,
      sortBy,
      isSortDirDesc,
      resetForm,
      searchQuery2,
      checkDatas,
      finishPrice,
      payListSend,
      goPopstore,
    } = useIndex(SCANNER_STORE_MODULE_NAME)

    return {
      productList,
      fetchStart,
      scannerData,
      searchQuery,
      searchData,
      totalPrice,
      resetPrice,
      refProductsTable,
      tableColumns,
      fetchProducts,
      confirmDelete,
      refetchData,
      testList,
      refNoticeShowModal,
      refShowNoticeComponent,
      showModal,
      showModalShown,
      shownId,
      sortBy,
      isSortDirDesc,
      resetForm,
      searchQuery2,
      checkDatas,
      finishPrice,
      payListSend,
      goPopstore,
    }
  },
}
</script>

<style lang="scss">
</style>